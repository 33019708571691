.registration-form-container {
  display: flex;
  position: relative;
}
.registration-form-form {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.registration-form-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #e5e5e5;
  border-width: 3px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.registration-form-text {
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  margin-bottom: 0;
}
.registration-form-text1 {
  color: #FFC107;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  margin-bottom: 0;
}
.registration-form-textinput {
  width: 100%;
  display: block;
  margin-top: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
}
.registration-form-textinput1 {
  width: 100%;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
}
.registration-form-textinput2 {
  width: 100%;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
}
.registration-form-textinput3 {
  width: 100%;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
}
.registration-form-confirm-registration {
  color: rgb(229, 229, 229);
  width: 100%;
  align-self: center;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: rgba(0, 0, 0, 0);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: rgb(255, 193, 7);
}
@media(max-width: 991px) {
  .registration-form-form {
    width: 100%;
  }
  .registration-form-container1 {
    wdth: 90%;
  }
}
@media(max-width: 767px) {
  .registration-form-form {
    width: 100%;
  }
  .registration-form-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .registration-form-form {
    width: 100%;
  }
  .registration-form-container1 {
    width: 100%;
  }
}
