.home-backup-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #142850;
}
.home-backup-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-backup-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-backup-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-backup-image {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-backup-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-backup-icon {
  width: 36px;
  height: 36px;
}
.home-backup-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-backup-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-backup-link1 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-backup-link2 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-backup-link3 {
  text-decoration: none;
}
.home-backup-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-backup-cta-btn {
  color: #E5E5E5;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #FFC107;
}
.home-backup-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-backup-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-backup-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-backup-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-icon02 {
  width: 24px;
  height: 24px;
}
.home-backup-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-backup-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-backup-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-backup-link7 {
  text-decoration: none;
}
.home-backup-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: auto;
  justify-content: center;
  background-repeat: no-repeat;
}
.home-backup-content-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.home-backup-image2 {
  width: auto;
  padding: 15px;
  object-fit: cover;
}
.home-backup-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-backup-heading {
  color: rgb(255, 193, 7);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-backup-text {
  color: rgb(229, 229, 229);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-backup-cta-btn1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #FFC107;
}
.home-backup-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-text01 {
  color: rgb(229, 229, 229);
  font-size: xx-large;
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
}
.home-backup-text04 {
  font-weight: 600;
}
.home-backup-banner1 {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-text06 {
  color: rgb(229, 229, 229);
  font-size: xx-large;
  text-align: center;
  font-family: fantasy;
  font-weight: 600;
  line-height: 2;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 32px;
  text-transform: capitalize;
}
.home-backup-text09 {
  font-weight: 600;
}
.home-backup-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.home-backup-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-backup-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-backup-text11 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-backup-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-backup-features-card {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon04 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-text12 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card1 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon07 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading02 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text13 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon10 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading03 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text14 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card3 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon13 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading04 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text19 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card4 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon16 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading05 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text20 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card5 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container5 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon19 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading06 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text25 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card6 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container6 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon22 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container6 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading07 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text26 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card7 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container7 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon25 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container7 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading08 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text29 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-features-card8 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-backup-icon-container8 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-backup-icon28 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-backup-text-container8 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-backup-heading09 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-backup-text30 {
  color: #E5E5E5;
  text-align: left;
}
.home-backup-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.home-backup-banner2 {
  width: 100%;
  display: flex;
  padding: 54px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-text31 {
  color: rgb(229, 229, 229);
  font-size: xx-large;
  text-align: center;
  font-family: auto;
  font-weight: 200;
  padding-top: var(--dl-space-space-tripleunit);
  margin-bottom: 32px;
  padding-bottom: var(--dl-space-space-tripleunit);
  text-transform: capitalize;
}
.home-backup-text34 {
  font-weight: 600;
}
.home-backup-cta-btn2 {
  color: #E5E5E5;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #FFC107;
}
.home-backup-speaker {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-container2 {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-banner3 {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-backup-text36 {
  color: #FFC107;
}
.home-backup-text37 {
  color: rgb(229, 229, 229);
  font-size: x-large;
  max-width: 1400px;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.home-backup-cta-btn3 {
  color: #E5E5E5;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #FFC107;
}
.home-backup-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.home-backup-banner4 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  padding: 48px;
  text-align: center;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.home-backup-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.home-backup-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-backup-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-backup-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-backup-icon31 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-backup-icon33 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-backup-icon35 {
  width: 24px;
  height: 24px;
}
.home-backup-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-backup-icon37 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .home-backup-hero {
    flex-direction: column-reverse;
  }
  .home-backup-content-container {
    flex-direction: column-reverse;
  }
  .home-backup-hero-text {
    width: 80%;
  }
  .home-backup-text01 {
    text-align: center;
  }
  .home-backup-text06 {
    text-align: center;
  }
  .home-backup-text31 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-backup-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-backup-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-backup-links-container {
    display: none;
  }
  .home-backup-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-backup-banner1 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-backup-heading-container {
    width: 100%;
  }
  .home-backup-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-backup-features-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card4 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card5 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card6 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card7 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-features-card8 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-backup-banner2 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-backup-speaker {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-backup-banner3 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-backup-text37 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-backup-banner4 {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .home-backup-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-backup-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-backup-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-backup-hero-text {
    width: 100%;
  }
  .home-backup-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-backup-banner1 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-backup-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-backup-features-card {
    width: 100%;
  }
  .home-backup-features-card1 {
    width: 100%;
  }
  .home-backup-text13 {
    color: #e5e5e5;
  }
  .home-backup-features-card2 {
    width: 100%;
  }
  .home-backup-text14 {
    color: #e5e5e5;
  }
  .home-backup-features-card3 {
    width: 100%;
  }
  .home-backup-text19 {
    color: #e5e5e5;
  }
  .home-backup-features-card4 {
    width: 100%;
  }
  .home-backup-text20 {
    color: #E5E5E5;
  }
  .home-backup-text22 {
    color: #e5e5e5;
  }
  .home-backup-features-card5 {
    width: 100%;
  }
  .home-backup-features-card6 {
    width: 100%;
  }
  .home-backup-text26 {
    color: #E5E5E5;
  }
  .home-backup-features-card7 {
    width: 100%;
  }
  .home-backup-text29 {
    color: #E5E5E5;
  }
  .home-backup-features-card8 {
    width: 100%;
  }
  .home-backup-text30 {
    color: #E5E5E5;
  }
  .home-backup-banner2 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-backup-speaker {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-backup-banner3 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-backup-banner4 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
