.thank-you-for-your-registration-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #142850;
}
.thank-you-for-your-registration-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.thank-you-for-your-registration-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.thank-you-for-your-registration-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.thank-you-for-your-registration-image {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.thank-you-for-your-registration-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.thank-you-for-your-registration-icon {
  width: 36px;
  height: 36px;
}
.thank-you-for-your-registration-links-container {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
}
.thank-you-for-your-registration-right-side {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.thank-you-for-your-registration-cta-btn {
  color: #E5E5E5;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #FFC107;
}
.thank-you-for-your-registration-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.thank-you-for-your-registration-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.thank-you-for-your-registration-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.thank-you-for-your-registration-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.thank-you-for-your-registration-icon02 {
  width: 24px;
  height: 24px;
}
.thank-you-for-your-registration-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.thank-you-for-your-registration-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.thank-you-for-your-registration-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.thank-you-for-your-registration-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.thank-you-for-your-registration-link3 {
  text-decoration: none;
}
.thank-you-for-your-registration-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: auto;
  justify-content: center;
  background-repeat: no-repeat;
}
.thank-you-for-your-registration-content-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.thank-you-for-your-registration-image2 {
  width: auto;
  padding: 15px;
  object-fit: cover;
}
.thank-you-for-your-registration-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.thank-you-for-your-registration-heading {
  color: rgb(255, 193, 7);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.thank-you-for-your-registration-text {
  color: rgb(229, 229, 229);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.thank-you-for-your-registration-cta-btn {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #FFC107;
}
.thank-you-for-your-registration-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.thank-you-for-your-registration-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.thank-you-for-your-registration-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.thank-you-for-your-registration-banner {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  padding: 48px;
  text-align: center;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.thank-you-for-your-registration-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5E5E5;
}
.thank-you-for-your-registration-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.thank-you-for-your-registration-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.thank-you-for-your-registration-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.thank-you-for-your-registration-link4 {
  display: contents;
}
.thank-you-for-your-registration-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.thank-you-for-your-registration-link5 {
  display: contents;
}
.thank-you-for-your-registration-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.thank-you-for-your-registration-link6 {
  display: contents;
}
.thank-you-for-your-registration-icon08 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.thank-you-for-your-registration-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.thank-you-for-your-registration-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .thank-you-for-your-registration-hero {
    flex-direction: column-reverse;
  }
  .thank-you-for-your-registration-content-container {
    flex-direction: column-reverse;
  }
  .thank-you-for-your-registration-hero-text {
    width: 80%;
  }
}
@media(max-width: 767px) {
  .thank-you-for-your-registration-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
  .thank-you-for-your-registration-icon {
    fill: var(--dl-color-gray-white);
  }
  .thank-you-for-your-registration-links-container {
    display: none;
  }
  .thank-you-for-your-registration-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .thank-you-for-your-registration-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .thank-you-for-your-registration-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .thank-you-for-your-registration-hero-text {
    width: 100%;
  }
  .thank-you-for-your-registration-banner {
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
