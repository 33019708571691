.blog-post-card2-blog-post-card {
  width: 100%;
  display: flex;
  max-width: 1400px;
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-post-card2-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-post-card2-container {
  display: flex;
  align-items: flex-start;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.blog-post-card2-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-text {
  color: #E5E5E5;
  font-weight: 600;
  text-transform: uppercase;
}
.blog-post-card2-text1 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-post-card2-text2 {
  margin-bottom: 32px;
}
.blog-post-card2-text3 {
  color: #E5E5E5;
  font-size: 1.25rem;
  margin-bottom: 32px;
}
.blog-post-card2-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card2-profile {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}



@media(max-width: 991px) {
  .blog-post-card2-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .blog-post-card2-blog-post-card {
    flex-direction: column;
  }
  .blog-post-card2-image {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-post-card2-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
